<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="tableOptions.loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="detailSync">同步考勤数据</el-button>
      </template>
    </vxe-toolbar>

    <vxe-grid ref="refTable" v-bind="tableOptions">
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.work_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_user_check_time_list="{ row }">
        <div v-for="(item, index) in row.user_check_time_list" :key="index">{{ item }}</div>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // filter
      filterData: {
        work_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },

      // table
      headerFilterUrl: '/admin/gcvip/dingding/searchHeard',
      tableOptions: {
        loading: true,
        size: 'mini',
        border: true,
        'max-height': this.$util.getViewHeight() - 148,
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          {
            field: 'nick_name',
            title: '姓名',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'user_name',
            title: '手机号',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'work_date',
            title: '工作时间',
            width: 180,
            slots: { header: 'header_daterange' }
          },
          {
            field: 'user_check_time_list',
            title: '打卡记录',
            slots: { default: 'default_user_check_time_list' }
          },
          {
            field: 'over_date_day_str',
            title: '加班时间',
            visible: false
          }
        ],
        data: []
      }
    }
  },
  created() {
    this.initColumn()
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 初始化列
    initColumn() {
      if (this.$tool.hasControl(['/dingding/saveOrUpdate'])) {
        this.tableOptions.columns.forEach((item) => {
          if (item.field === 'over_date_day_str') {
            item.visible = true
          }
        })
      }
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.tableOptions.pagerConfig.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.tableOptions.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/search',
        data: {
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.tableOptions.data = res.data.page.list
            this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
            this.tableOptions.pagerConfig.total = res.data.page.totalRow
            this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.tableOptions.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 同步考勤数据
    detailSync() {
      let pickerDate = []
      if (this.filterData.work_date.length) {
        pickerDate = [
          `${this.filterData.work_date[0]} 00:00:00`,
          `${this.filterData.work_date[1]} 23:59:59`
        ]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/synchronization',
        data: {
          pickerDate
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('考勤数据同步成功')
          this.getListData()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
